import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="message-bar">
        Demo may take 3-5 minutes to load. Go into aerial mode or inside the house to interact with the AI!
      </div>
      <iframe
        id="arcane-player-frame"
        src="https://embed.arcanemirage.com/e32a8027-7b97-4628-bed2-0ffe94b351a9?key=aWQ9MzA3NiZrZXk9ZTMyYTgwMjctN2I5Ny00NjI4LWJlZDItMGZmZTk0YjM1MWE5JmlkbGU9NjAmZHVyYXRpb249MCZlbmQ9Jm1pYz1mYWxzZSZjYXB0dXJlPWZhbHNlJmV2ZW50cz1mYWxzZSZhdXRvcGxheT1mYWxzZSZoaWRlVUk9ZmFsc2UmdG9rZW49WEZocC0tRmNEWlJnJmludHJvPW51bGwmdGh1bWJuYWlsPWh0dHBzJTNBJTJGJTJGaW1hZ2VzLmFyY2FuZW1pcmFnZS5jb20lMkZwcm9qZWN0cyUyRnRodW1ibmFpbHMlMkY2NTkwMDk2Yy05NWU4LTQ5MGItOGJhMS0yOTk0ZDdhMGM1OWJfMTEyMHg2MzAuanBlZw=="
        frameBorder="0"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        allow="fullscreen"
      ></iframe>
    </div>
  );
}

export default App;